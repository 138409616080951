<template>
  <div>
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :is-sidebar-active.sync="isSidebarActive"
      :is-selected-category="isSelectedCategory"
      :show.sync="show"
      :data="sidebarData"
    />
    <b-row>
      <b-col md="12">
        <b-card>
          <b-col
            md="12"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex mr-2"
                @click="openMenuSort()"
              >
                <span class="align-middle">{{ $t('Sort') }}</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex mr-2"
                @click="importProductCategory()"
              >
                <span class="align-middle">{{ $t('Import') }}</span>
              </b-button>
            </div>
          </b-col>

        </b-card>

      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="selected1"
                size="34"
                :src="selected1.image"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected1"
                  class="mb-0"
                >
                  {{ selected1.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5
                  class="mb-0"
                >
                  {{ $t('Category level') }} : 1
                </h5>
              </b-col>

            </div>
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew(0)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery1"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="align-text-bottom line-height-1">
                          {{ this.$t('ACTION') }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in category1"
                  :key="filter._id"
                  :active="activeList1(filter)"
                  @click="selectCategory(filter , 0)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p
                        class="align-text-bottom line-height-1"
                      >{{ filter.refId }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p
                        v-for="(item,index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >{{ (item.lang).toUpperCase() }} : {{ item.value }}</p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="sidebarEdit(filter, 0)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteData( filter._id )">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </section>
        </b-card>

      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center">
              <b-avatar
                v-if="selected2"
                size="34"
                :src="selected2.image"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected2"
                  class="mb-0"
                >
                  {{ selected2.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5
                  class="mb-0"
                >
                  {{ $t('Category level') }} : 2
                </h5>
              </b-col>
            </div>
            <div
              v-if="selected1"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew(1)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >

              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery2"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="align-text-bottom line-height-1">
                          {{ this.$t('ACTION') }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in category2"
                  :key="filter._id"
                  :active="activeList2(filter)"
                  @click="selectCategory(filter , 1)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p
                        class="align-text-bottom line-height-1"
                      >{{ filter.refId }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p
                        v-for="(item,index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >{{ (item.lang).toUpperCase() }} : {{ item.value }}</p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="sidebarEdit(filter, 1)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteData( filter._id )">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </section>
        </b-card>

      </b-col>
      <b-col md="4">
        <b-card
          class="chat-widget"
          no-body
        >
          <b-card-header>
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar
                v-if="selected3"
                size="34"
                :src="selected3.image"
                class="mr-50"
              />
              <b-avatar
                v-else
                size="34"
                :src="require('@/assets/images/blank/no_image.png')"
                class="mr-50"
              />
              <b-col>
                <h5
                  v-if="selected3"
                  class="mb-0"
                >
                  {{ selected3.name[0].value }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  {{ $t('Not Selected') }}
                </h5>
                <h5
                  class="mb-0"
                >
                  {{ $t('Category level') }} : 3
                </h5>
              </b-col>
            </div>
            <div
              v-if="selected2"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex"
                @click="sidebarAddNew(2)"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                <!-- <span class="align-middle">Add Users</span> -->
              </b-button>
            </div>
          </b-card-header>
          <section class="chat-app-window">
            <vue-perfect-scrollbar
              ref="refChatLogPS1"
              class="user-chats scroll-area"
              :options="perfectScrollbarSettings"
            >
              <!-- Filters -->
              <b-list-group class="list-group-filters">
                <b-form-input
                  v-model="searchQuery3"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')+'...'"
                />
                <b-list-group-item disabled>
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Image') }}
                      </p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('RefId') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p class="align-text-bottom line-height-1">
                        {{ this.$t('Name') }}
                      </p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <p class="align-text-bottom line-height-1">
                          {{ this.$t('ACTION') }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
                <b-list-group-item
                  v-for="filter in category3"
                  :key="filter._id"
                  :active="activeList3(filter)"
                  @click="selectCategory(filter , 2)"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-avatar
                        v-if="filter.image"
                        size="34"
                        :src="filter.image"
                        class="mr-50"
                      />
                      <b-avatar
                        v-else
                        size="34"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-50"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <p
                        class="align-text-bottom line-height-1"
                      >{{ filter.refId }}</p>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >

                      <p
                        v-for="(item,index) in filter.name"
                        :key="index"
                        class="align-text-bottom line-height-1"
                      >{{ (item.lang).toUpperCase() }} : {{ item.value }}</p>

                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                      class="b-col-custom"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-dropdown
                          variant="link"
                          no-caret
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="sidebarEdit(filter ,2)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Edit') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="deleteData( filter._id )">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>

            </vue-perfect-scrollbar>
          </section>
        </b-card>

      </b-col>
    </b-row>

  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BLink,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import storeModule from '@/store/services/eCommerce/productCategory'
import debounce from 'debounce'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Sidebar from './DataSidebar.vue'

const STORE_MODULE_NAME = 'productCategory'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BAvatar,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuePerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    PerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      isSidebarActive: false,
      sidebarData: {},
      isSelectedCategory: null,
      searchQuery1: '',
      searchQuery2: '',
      searchQuery3: '',
      selected1: null,
      selected2: null,
      selected3: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      currentPage: 1,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    category1() {
      const regex = new RegExp(this.searchQuery1, 'i')
      const result = this.respData.filter(p => p.position === 0 && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      return result || []
    },
    category2() {
      let result = []
      const regex = new RegExp(this.searchQuery2, 'i')

      if (this.selected1) {
        result = this.respData.filter(p => p.parent_id === this.selected1.id && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      }
      return result || []
    },
    category3() {
      let result = []
      const regex = new RegExp(this.searchQuery3, 'i')

      if (this.selected2) {
        result = this.respData.filter(p => p.parent_id === this.selected2.id && (regex.test(p.refId) || regex.test(p.name.map(n => n.value))))
      }
      return result || []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: 0,
      searchQuery: '',
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // 
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {

    typing(data) {
      // this.respData.forEach(category => {
      //   console.log(category._id)
      // })
      const matching = this.respData.filter(p => {
        const regex = new RegExp(data, 'i')
        // eslint-disable-next-line no-mixed-operators
        return p.position === 0 && regex.test(p.refId) || regex.test(p.name.map(n => n.value))
      })
      console.log(matching)
      console.log(data)
    },
    activeList3(value) {
      if (this.selected3) {
        if (this.selected3._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    activeList2(value) {
      if (this.selected2) {
        if (this.selected2._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    activeList1(value) {
      if (this.selected1) {
        if (this.selected1._id === value._id) {
          return true
        }
        return false
      }
      return false
    },
    openMenuSort() {
      this.$router.push({ path: '/e-commerce/productCategory/sort' })
    },
    importProductCategory() {
      this.$router.push({ name: 'e-commerce-productCategory-import' })
    },
    selectCategory(value, position) {
      if (position === 0) {
        this.selected1 = value
        this.selected2 = null
        this.selected3 = null
      } else if (position === 1) {
        this.selected2 = value
        this.selected3 = null
      } else if (position === 2) {
        this.selected3 = value
      }
    },
    sidebarAddNew(selectedPosition) {
      this.sidebarData = {}
      if (selectedPosition === 0) {
        this.isSelectedCategory = null
      } else if (selectedPosition === 1) {
        this.isSelectedCategory = this.selected1
      } else if (selectedPosition === 2) {
        this.isSelectedCategory = this.selected2
      }
      this.isSidebarActive = true
    },
    sidebarEdit(item, selectedPosition) {
      this.sidebarData = item
      if (selectedPosition === 0) {
        this.isSelectedCategory = null
      } else if (selectedPosition === 1) {
        this.isSelectedCategory = this.selected1
      } else if (selectedPosition === 2) {
        this.isSelectedCategory = this.selected2
      }
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              .then(result => {
                this.show = false
                
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
        //  else {
        //   this.showToast(
        //     'warning',
        //     'top-right',
        //     `${this.$t('DELETE_CANCEL')}!`,
        //     'AlertCircleIcon',
        //     this.$t('DELETE_CANCEL'),
        //   )
        // }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
@import '@core/scss/vue/libs/vue-select.scss';
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
